<template>

    <a-layout class="yj-conten-div">
        <!--<a-breadcrumb style="margin: 6px 10px 0 ;display:none">
          <a-breadcrumb-item>首页</a-breadcrumb-item>
          <a-breadcrumb-item>首页</a-breadcrumb-item>
                 拉新 = 0,
        潜在 = 1,
        意向 = 2,
        成交 = 3

        </a-breadcrumb>-->
        <a-layout-content>
            <div class="yj-conten" style="background:#fff;padding:20px 20px 0;min-height:calc(100% - 48px)">
                <a-row class="yj-form">
                    <a-col :span="24">
                        <label>类别 :</label>
                        <a-radio-group v-model="category" @change="radioChange" button-style="solid">
                            <a-radio-button value="-1">全部</a-radio-button>
                            <a-radio-button value="0">拉新</a-radio-button>
                            <a-radio-button value="1">意向</a-radio-button>
                            <a-radio-button value="2">咨询</a-radio-button>
                            <a-radio-button value="3">成交</a-radio-button>
                        </a-radio-group>
                        <label style="margin-left:100px;">排序 :</label>
                        <a-radio-group v-model="OrderBy" @change="getTableData" button-style="solid">
                            <a-radio-button value="-1">时间</a-radio-button>
                            <a-radio-button value="0">访问</a-radio-button>
                            <a-radio-button value="1">拉新</a-radio-button>
                            <a-radio-button value="2">分享</a-radio-button>
                            <a-radio-button value="3">咨询</a-radio-button>
                        </a-radio-group>
                        <!--<a-button type="primary" style="float:right" @click="GetPCLoginURL">客服</a-button>-->
                    </a-col>
                    
                    <a-col :span="24">
                        <label>客户 :</label>
                        <a-input id="name"
                                 contextmenu="2455"
                                 :value="name"
                                 style="width:260px"
                                 placeholder="请输入客户名称"
                                 @change="name_change"></a-input>

                        <label style="margin-left:10px">介绍人 :</label>
                        <a-input id="introducer"
                                 contextmenu="2455"
                                 :value="introducer"
                                 style="width:260px"
                                 placeholder="请输入介绍人名称"
                                 @change="introducer_change"></a-input>

                        <label style="margin-left:10px">推荐人 :</label>
                        <a-input id="introducer"
                                 contextmenu="2455"
                                 :value="SharedName"
                                 style="width:260px"
                                 placeholder="请输入推荐人名称"
                                 @change="SharedName_change"></a-input>

                        <label style="margin-left:10px">加入时间 :</label>
                        <a-range-picker style="width:250px;"
                                        :value="Time"
                                        @change="handleChange" />

                        <a-button type="primary" @click="search">查询</a-button>
                        <a-button @click="exportdata">导出</a-button>
                    </a-col>
                </a-row>
                <!--:customRow="handleClickRow"-->
                <a-table bordered :columns="columns"
                         rowKey="UserID"
                         :data-source="tableData"
                         :pagination="pagination"
                         @change="pagerChange"
                         class="yj-table-img">
                    <template v-slot:Infotemplate="text,record">
                        <div class='umeta' @click="jump(record)">
                            <div class='uimg'>
                                <img class="uheadimg" :src="record.HeadImg || '/Image/default-avatar.svg'" />
                            </div>
                            <div class="uinfo">
                                <h4 class="uname">{{record.NickName}}</h4>
                                <a-tag color="green" v-if="record.AttentionCategoryDisplay=='拉新'"> {{record.AttentionCategoryDisplay}}</a-tag>
                                <a-tag color="blue" v-else-if="record.AttentionCategoryDisplay=='意向'"> {{record.AttentionCategoryDisplay}}</a-tag>
                                <a-tag color="orange" v-else-if="record.AttentionCategoryDisplay=='咨询'"> {{record.AttentionCategoryDisplay}}</a-tag>
                                <a-tag color="pink" v-else-if="record.AttentionCategoryDisplay=='成交'"> {{record.AttentionCategoryDisplay}}</a-tag>
                            </div>

                        </div>

                    </template>
                    <!-- <span slot="imgs" slot-scope="text">
        <img class="imgPreview" :src="text" alt="头像" />
    </span> -->
                    <!-- <span slot="name_slot" slot-scope="text,record">
        <a @click="jump(record)">{{record.NickName}}</a>
    </span> -->
                    <span slot="Introducer" slot-scope="text,record">
                        <a @click="PartnerJump(record,0)">{{record.Introducer}}</a>
                    </span>
                    <span slot="ShareUserName" slot-scope="text,record">
                        <a @click="jumpshare(record)">{{record.ShareUserName}}</a>
                    </span>
                    <span slot="VisitingCount" slot-scope="text,record">
                        <a @click="achievement(record)">{{record.VisitingCount}}</a>
                    </span>
                    <span slot="PullNewCount" slot-scope="text,record">
                        <a @click="AgentGuestLogList(0,record)">{{record.PullNewCount}}</a>
                    </span>
                    <span slot="SharedCount" slot-scope="text,record">
                        <a @click="achievement(record)">{{record.SharedCount}}</a>
                    </span>
                    <span slot="PhoneCount" slot-scope="text,record">
                        <a @click="AgentGuestLogList(2,record)">{{record.PhoneCount}}</a>
                    </span>


                    <template v-slot:GPStemplate="text,record">


                        <p>距离{{getdistance(record.LastGPS)}}</p>
                        <p class="addrs">{{record.LastGPSName}}</p>
                    </template>

                    <span slot="LastGPS" slot-scope="text,record">
                        {{getdistance(record.LastGPS)}}
                    </span>
                    <span slot="SharedUserType" slot-scope="text,record">
                        {{GetPromotionType(record.SharedUserType)}}
                    </span>
                </a-table>
            </div>
        </a-layout-content>
    </a-layout>

</template>
<script>
    import http from "@/Plugin/Http.js";
    import util from "@/Plugin/util.js";
    import moment from 'moment';
    export default {
        name: "Operations_List",
        data() {
            return {
                columns: [
                     {
                        title: "客户",
                        dataIndex: 'UserID',
                        scopedSlots: { customRender: "Infotemplate" }
                    },
                    // {
                    //     title: "状态",
                    //     dataIndex: "AttentionCategoryDisplay",
                    // },
                    // {
                    //     title: "头像",
                    //     dataIndex: "HeadImg",
                    //     key: "HeadImg",
                    //     align: "center",
                    //     scopedSlots: { customRender: "imgs" },
                    // },
                    // {
                    //     title: "昵称/姓名",
                    //     dataIndex: "NickName",
                    //     ellipsis: true,
                    //     scopedSlots: { customRender: "name_slot" },

                    // },
                    {
                        title: "电话",                        
                        dataIndex: "Phone",
                    },
                    {
                        title: "介绍人",
                        dataIndex: "Introducer",
                        scopedSlots: { customRender: "Introducer" }
                    },

                    {
                        title: "推荐人",
                        dataIndex: "ShareUserName",
                        scopedSlots: { customRender: "ShareUserName" }
                    },

                    {
                        title: "来源",
                        scopedSlots: { customRender: "SharedUserType" }
                    },
                  
                    // {
                    //     title: "距离",
                    //     scopedSlots: { customRender: "LastGPS" }
                    // },
                    {
                        title: "访问",
                        dataIndex: "VisitingCount",
                        scopedSlots: { customRender: "VisitingCount" }

                    },
                    
                    {
                        title: "拉新",
                        dataIndex: "PullNewCount",
                        scopedSlots: { customRender: "PullNewCount" }

                    },
                  
                    {
                        title: "咨询",
                        dataIndex: "PhoneCount",
                        scopedSlots: { customRender: "PhoneCount" }

                    },
                      {
                        title: "分享",
                        dataIndex: "SharedCount",
                        scopedSlots: { customRender: "SharedCount" }

                    },
                    {
                        title: "领取卡券",
                        dataIndex: "GetCount",
                    },
                    // {
                    //     title: "核销",
                    //     dataIndex: "CheckCount",
                    // },
                      {
                        title: "位置距离",
                        dataIndex: "LastGPSName",
                        scopedSlots: { customRender: "GPStemplate" }
                    },
                    {
                        title: "首次访问时间",
                        customRender: (text, item) => {

                            var temp = new Date(util.TimeStamptoDateTime(item.JoinTime, 'yyyy-MM-dd hh:mm:ss'));

                            return util.formatDate3(temp);
                        }
                    },
                    {
                        title: "最近访问时间",
                        customRender: (text, item) => {

                            var temp = new Date(util.TimeStamptoDateTime(item.LastVisitTime, 'yyyy-MM-dd hh:mm:ss'));

                            return util.formatDate3(temp);
                        }
                    }

                ],
                category: "",
                tableData: [],
                OrderBy:-1,
                name: "",
                introducer:"",
                SharedName:"",
                pagination: {
                    total: 0,
                    defaultPageSize: 0,
                    current: 1,
                    showTotal: total => `共 ${total} 条数据`
                },
                customRowOption: {
                    on: {
                        click: this.RowClick
                    }
                },
                Time: [],
                BeginTime: "",
                EndTime: "",
                SitetableData: [],
                BeginDate: new Date(),
                EndDate: new Date(),
            };
        },
        methods: {
            //拉新 咨询
            AgentGuestLogList(e,record) {
                var self = this;
                var BeginDates = new Date(util.TimeStamptoDateTime(record.JoinTime, 'yyyy-MM-dd hh:mm:ss'));
                var EndDates = new Date(util.TimeStamptoDateTime(record.LastVisitTime, 'yyyy-MM-dd hh:mm:ss'));
                self.$router.push({
                    name: "Operations_AgentGuestLogList",
                    query: {
                        Category: e,
                        BeginDate: util.TimeStamptoDateTime("/Date(" + new Date(BeginDates).getTime() + ")/", 'yyyy-MM-dd'),
                        EndDate: util.TimeStamptoDateTime("/Date(" + new Date(EndDates).getTime() + ")/", 'yyyy-MM-dd'),
                    }
                })
            },
            //访问
            achievement(e) {
                var self = this;
                var BeginDates = new Date(util.TimeStamptoDateTime(e.JoinTime, 'yyyy-MM-dd hh:mm:ss'));
                var EndDates = new Date(util.TimeStamptoDateTime(e.LastVisitTime, 'yyyy-MM-dd hh:mm:ss'));
                
                self.$router.push({
                    name: "Operations_QRcodeRecordList",
                    query: {
                        BeginDate: util.TimeStamptoDateTime("/Date(" + new Date(BeginDates).getTime() + ")/", 'yyyy-MM-dd'),
                        EndDate: util.TimeStamptoDateTime("/Date(" + new Date(EndDates).getTime() + ")/", 'yyyy-MM-dd'),
                    }
                })
            },

            exportdata: function () {
                var self = this
                var op = {
                    url: "/OperationsModule/Operations/ExcelAgentFansList",
                    data: {
                        pageIndex: self.pagination.current,
                        Category: self.category,
                        name: self.name,
                        BeginTime: self.BeginTime,
                        EndTime: self.EndTime,
                    },//下载接口参数
                    FileName: '客户列表.xlsx',//下载文件重命名
                    ContentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'//文件Mine类型
                };
                http.DownLoadFile(op);
            },
            handleClickRow(record) {
                var self = this
                return {
                    on: {
                        click: () => {
                            //console.log(record.UserID)
                            self.$router.push({
                                name: "OperationsDetail", query: {
                                    ID: record.UserID
                                }
                            })
                        }
                    }
                }
            },
            jump: function (data) {

                var self = this;
                self.$router.push({
                    name: "OperationsDetail", query: {
                        ID: data.UserID
                    }
                })
            },
            jumpshare: function (data) {
                var self = this;
                self.$router.push({
                    name: "OperationsDetail", query: {
                        ID: data.SharedUserID
                    }
                })
            },
            PartnerJump: function (data, type) {
                var self = this;
                var ID = type == 0 ? data.IntroducerID : data.SharedUserID
                self.$router.push({
                    name: "PartnerList_PartnerDetail", query: {
                        ID: ID
                    }
                })
            },
            customRow: function () {
                return this.customRowOption;
            },
            RowClick: function (e) {
                console.log(e);
            },
            name_change: function (item) {
                var self = this;
                self.name = item.target.value;
            },
            introducer_change: function (item) {
                var self = this;
                self.introducer = item.target.value;
            },
            SharedName_change: function (item) {
                var self = this;
                self.SharedName = item.target.value;
                console.log(item.target.value);
            },
            radioChange: function (item) {
                var self = this;
                self.category = item.target.value;
                // self.getTableData();
                self.search();
            },
            pagerChange: function (item) {
                var self = this;
                self.pagination.current = item.current;
                self.getTableData();
            },
            search: function () {
                this.pagination.current = 1;
                this.getTableData();
            },
            getTableData: function () {
                var self = this;
                console.log(self.OrderBy);
                var op = {
                    url: "/OperationsModule/Operations/AgentFansList",
                    data: {
                        pageIndex: self.pagination.current,
                        Category: self.category,
                        name: self.name,
                        BeginTime: self.BeginTime,
                        EndTime: self.EndTime,
                        introducer: self.introducer,
                        sharedName:self.SharedName,
                        OrderBy: self.OrderBy,
                    },
                    OnSuccess: function (data) {
                        console.log(data);
                        self.tableData = data.data.Data;
                        self.pagination.total = data.data.Total;
                        self.pagination.defaultPageSize = data.data.RowCount;
                        self.pagination.current = data.data.PageIndex;
                    }
                };
                http.Post(op);
            },
            GetPCLoginURL: function () {
                var op = {
                    url: "/OperationsModule/Operations/GetPCLoginURL",
                    data: {
                    },
                    OnSuccess: function (data) {
                        var a = document.createElement("a");
                        a.setAttribute("href", data.data);
                        a.setAttribute("target", "_blank");
                        a.click();
                    }
                };
                http.Post(op);
            },
            handleChange(value) {
                this.Time = value;
                if (value.length == 0) {
                    this.BeginTime = "";
                    this.EndTime = "";
                } else {
                    this.BeginTime = util.formatDate(this.Time[0]._d);
                    this.EndTime = util.formatDate(this.Time[1]._d);
                }
            },
            getSite: function () {
                var self = this;
                var op = {
                    url: "/ShopModule/ShopManage/GetSiteList",
                    data: {
                        pageIndex: 1,
                    },
                    OnSuccess: function (data) {
                        self.SitetableData = data.data.Data;
                    }
                };
                http.Post(op);
            },
            getdistance(value) {
                var self = this;
                var rt;
                if (self.SitetableData.length == 0 || value == undefined) {
                    rt = "未知";
                } else {
                    var lat1 = value.split(',')[1];
                    var lng1 = value.split(',')[0];
                    var lat0 = self.SitetableData[0].Position.split(',')[1];
                    var lng0 = self.SitetableData[0].Position.split(',')[0];
                    rt = lat0 && lng0 && lat1 && lng1 && (util.getFlatternDistance(lat0, lng0, lat1, lng1) / 1000).toFixed(1).toString() + "Km" || "未知";
                }
                return rt;
            },

            GetPromotionType(rt) {
                switch (rt) {
                    case 0:
                        rt = "介绍人分享";
                        break;
                    case 1:
                        rt = "客户分享";
                        break;
                }
                return rt;
            },
        },
        mounted() {
            this.getTableData();
            this.getSite();
        },
        beforeMount() {
            var self = this;
            var Category = this.$route.query.Category;
            if (!util.isBlank(Category)) {
                self.category = Category.toString();
            } else {
                self.category = "-1"
            }
            if (!util.isBlank(this.$route.query.BeginDate) && !util.isBlank(this.$route.query.EndDate)) {
                self.BeginTime = this.$route.query.BeginDate;
                self.EndTime = this.$route.query.EndDate;
                this.Time = [moment(self.BeginTime, 'YYYY-MM-DD'), moment(self.EndTime, 'YYYY-MM-DD')];
            }
        },
        computed: {},
        components: {}
    };
</script>
<style scoped>
    .imgPreview {
        border-radius: 4px;
        width: 50px;
        height: 50px;
    }
    .addrs{max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-align: left !important;
    margin: 0;
    font-size: 12px;
    white-space: normal;}
    .umeta{display:grid;grid-template-columns:70px 1fr;}
    .uheadimg{width:48px;height:48px;border-radius: 50%;}
    .uinfo{cursor: pointer;}
    .uname{margin-bottom:5px}
    .uname:hover{color:#2281f8}
</style>